import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/reset.css'
import axios from "axios"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

axios.defaults.timeout = 5000;
axios.defaults.baseURL = "http://boss.uenet.online/w";
// axios.defaults.baseURL = "http://127.0.0.1:8069/w";
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')